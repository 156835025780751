<template>
  <section
    id="services"
    v-observe-visibility="{
      callback: (isVisible, entry) => changeAnchor(isVisible, entry),
      intersection: {
        threshold: 0.8
      },
    }"
    class="overflow-hidden py-10"
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-col
        class="text-center"
        cols="10"
      >
        <v-row
          class="fill-height"
          align="start"
          justify="center"
        >
          <v-col
            cols="12"
            md="10"
          >
            <base-heading class="info--text">
              Services
            </base-heading>

            <base-text class="py-3 text-justify">
              Depuis plus de 2 ans, <i>Pierre FACQ Business</i> accompagne ses clients aussi bien dans la
              <b>fabrication de solutions sur-mesure de haute qualité</b> que dans la
              <b>reprise de projets complexes</b> nécéssitant une expertise et une
              attention aux détails toute particulière.
            </base-text>
            <base-text>
              Découvrez les différents services proposés
            </base-text>
          </v-col>

          <v-col
            v-for="(service, i) in services"
            :key="i"
            class="text-center pa-10"
            :cols="$vuetify.breakpoint.mobile ? 12 : 3"
          >
            <v-lazy
              :id="service.id"
              v-model="isActive"
              :options="{
                threshold: .5,
              }"
              :transition="service.transition"
            >
              <div>
                <v-avatar
                  class="elevation-6 mb-3"
                  color="warning"
                  size="92"
                  tile
                >
                  <v-icon
                    dark
                    size="52"
                    v-text="service.icon"
                  />
                </v-avatar>
                <base-text>
                  <div
                    class="font-weight-regular primary--text mb-5"
                    v-text="service.name"
                  />
                  <div
                    v-for="(blurb, j) in service.blurbs"
                    :key="j"
                  >
                    {{ blurb }}
                  </div>
                </base-text>
              </div>
            </v-lazy>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  import { nav } from '@/components/mixins/nav'

  export default {
    name: 'Services',
    mixins: [nav],
    data: () => ({
      isActive: false,
      services: [
        {
          id: 'expertise',
          name: 'Expertise',
          icon: 'mdi-lightbulb-on-outline',
          blurbs: ['Définition des besoins', 'AMOA'],
          transition: 'scroll-x-transition',
        },
        {
          id: 'conception',
          name: 'Conception',
          icon: 'mdi-head-cog-outline',
          blurbs: ['Architecture de données', 'Architecture applicative'],
          transition: 'scroll-y-transition',
        },
        {
          id: 'development',
          name: 'Développement',
          icon: 'mdi-code-tags',
          blurbs: ['Solutions complètes', 'Ajout de fonctionnalités', 'Mise à niveau'],
          transition: 'scroll-y-reverse-transition',
        },
        {
          id: 'support',
          name: 'Support',
          icon: 'mdi-all-inclusive',
          blurbs: ['Maintenance', 'Accompagnement'],
          transition: 'scroll-x-reverse-transition',
        },
      ],
    }),
  }
</script>
<style lang="sass">
#expertise .scroll-x-transition-enter-active
  transition-delay: 0.5s !important
#conception .scroll-y-transition-enter-active
  transition-delay: 1s !important
#development .scroll-y-reverse-transition-enter-active
  transition-delay: 1.5s !important
#support .scroll-x-reverse-transition-enter-active
  transition-delay: 2s !important
</style>
