var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: function (isVisible, entry) { return _vm.changeAnchor(isVisible, entry); },
    intersection: {
      threshold: 0.8
    },
  }),expression:"{\n    callback: (isVisible, entry) => changeAnchor(isVisible, entry),\n    intersection: {\n      threshold: 0.8\n    },\n  }"}],staticClass:"overflow-hidden py-10",attrs:{"id":"services"}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"10"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('base-heading',{staticClass:"info--text"},[_vm._v(" Services ")]),_c('base-text',{staticClass:"py-3 text-justify"},[_vm._v(" Depuis plus de 2 ans, "),_c('i',[_vm._v("Pierre FACQ Business")]),_vm._v(" accompagne ses clients aussi bien dans la "),_c('b',[_vm._v("fabrication de solutions sur-mesure de haute qualité")]),_vm._v(" que dans la "),_c('b',[_vm._v("reprise de projets complexes")]),_vm._v(" nécéssitant une expertise et une attention aux détails toute particulière. ")]),_c('base-text',[_vm._v(" Découvrez les différents services proposés ")])],1),_vm._l((_vm.services),function(service,i){return _c('v-col',{key:i,staticClass:"text-center pa-10",attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 12 : 3}},[_c('v-lazy',{attrs:{"id":service.id,"options":{
              threshold: .5,
            },"transition":service.transition},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('div',[_c('v-avatar',{staticClass:"elevation-6 mb-3",attrs:{"color":"warning","size":"92","tile":""}},[_c('v-icon',{attrs:{"dark":"","size":"52"},domProps:{"textContent":_vm._s(service.icon)}})],1),_c('base-text',[_c('div',{staticClass:"font-weight-regular primary--text mb-5",domProps:{"textContent":_vm._s(service.name)}}),_vm._l((service.blurbs),function(blurb,j){return _c('div',{key:j},[_vm._v(" "+_vm._s(blurb)+" ")])})],2)],1)])],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }